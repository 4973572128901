@import "../../../res/consts/colors.scss";


.containerDropdownInput{
    .dropdownDropdownInput{
        border: 1px solid #EAEEF1;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: white;
 
        #dropdown-basic{
            width: 100%;
            text-align: left;
            display: flex;
            border:unset;
            padding: unset;
            border-radius: 0;
            background: unset;
            .dropdown{
                z-index: 2;
            }
            input{
                line-height: 20px !important;
                height: 20px;
                min-height: 20px !important;
                padding: 0;
                width: 100%;
                &.chosenItemTextDropdownInput{
                    color:$dark_grey;
                    font-weight: 600;
                    border:unset !important;
                    margin-left: 1rem !important;
                    &::placeholder{
                        color:$grey !important;
                    }
                }
                &:focus-visible{
                    border:unset !important;
                    outline-style: unset;
                }
            }
            .personIconDropdownInput{
                margin-top: -2px;
            }
            

            &:after{
                display: none;
            }

            &:focus{
                box-shadow: unset;
            }
        }
        .dropdown-menu{
            width:100%;
            background: white;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            overflow-y: auto;
            max-height: 158px;
            a{
                font-size: 12px;
                line-height: 20px;
                font-weight: normal;
                color:$dark_grey;
                white-space: normal;
                &:hover{
                    background-color: $light_blue;
                    font-weight: bold;
                    color:white
                }
            }
            &::-webkit-scrollbar-thumb {
                height: 5px;
                width:5px;
                background: rgb(189, 189, 189);
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            &::-webkit-scrollbar {
                display: block;
                height: 5px;
                width:5px;
                margin: 5px 10px 5px 0px;
            }
        }
    }
    .backgroundDropdownInput{
        position: absolute;
        width:100vw;
        height: 100vh;
        left:0;
        top:0;
        z-index: 1;
    }
}

.hint {
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    color: #adb5bd;
    white-space: normal;
    padding: 0.25rem 1rem;
}