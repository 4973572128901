@import "../../res/consts/colors.scss";

.containerUserInfoPage{
    //position: relative;
    .topFilter{
        background-color: $light_grey;
        border-radius: 5px;
        display: flex;
        grid-gap:20px;
        .containerDropdownInput{
            flex-grow: 1;
        }
        .refreshButton{
            display: flex;
            align-items: center;
            height: 36px;
            padding: 10px 15px;
            background-color: white;
            cursor:pointer;
            border-radius: 5px;
            border: 1px solid #EAEEF1;
            position: relative;
            z-index: 10;
            span{
                color:$black;
                line-height: 16px;
                font-size: 12px;
                font-weight: 600;
                margin-left: 10px;
            }
        }
}
    .containerObjectiveDiagrams > .containerCardCarousel > .arrowsCardCarousel {
        .arrowRightCardCarousel {
                position: absolute;
                top:calc(50% - 32px);
                right: 0;

                @media only screen and (max-width: 769px) {
                    top:calc(50% - 22px);
                }
        }

        .arrowLeftCardCarousel {
                position: absolute;
                top:calc(50% - 32px);
                left: 0;

                @media only screen and (max-width: 769px) {
                    top:calc(50% - 22px);
                }
        }
    }
}