
.containerCardCarousel{
    position: relative;
    .arrowsCardCarousel{
        img{
            cursor: pointer;
            z-index: 8;
        }
    }
    .overflowCardCarousel{
        overflow: hidden;

        .carouselCardCarousel{
            display: flex;
            position: relative;
            z-index: 1;
            transition: 1s;
        }
    }
}