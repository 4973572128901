@import "../../res/consts/colors.scss";

.messagesModalContent{
    .messagesModalHeader{
        color:$black;
        font-size:32px;
        font-weight: 700;
        width:100%;
        text-align: center;
        margin-bottom:10px;
        display: flex;
        flex-direction: column;
        height: 64px;
        .messagesModalHeaderComapny{
            color: $chat_grey;
            font-size: 16px;
            line-height: 22px;
        }
    }
    .messagesConteiner{
        //height: 550px;
        border: 1px solid $border_white;
        
        display:flex;
        flex-direction: column;
        .messagesData{
            flex-grow: 1;
            padding: 10px;
            overflow-y: auto;
            height: 500px;
            display: flex;
            //flex-direction: column-reverse;
            .messagesConteinerEmpty{
                color:$grey;
                font-size:16px;
                font-weight: 400;
                line-height: 22px;
            }
            .messagesChatContainer{
                .messagesChatDate{
                   font-size:16px;
                   color: $chat_grey;
                   line-height: 22px;
                   width:100%;
                   text-align: center;
                   font-weight: 600;
                   margin-bottom: 20px;
                   span{
                        font-weight: 400;
                   }  
                }
                .messageChatContainer{
                    margin-bottom: 20px;
                    position: relative;
                    .messageItem{
                        max-width:90%;
                        width:fit-content;
                        font-size:17px;
                        line-height: 22px;
                        font-weight: 400;
                        padding: 8px 12px;
                        border-radius: 17.5px;
                        position: relative;
                        word-wrap: break-word;
                        text-align: start;
                        &.notMyMessage{
                            color:black;
                            background-color: $chat_grey_background;
                            &:after{
                                bottom: -0.1rem;
                                content: "";
                                height: 1rem;
                                position: absolute;
                                background-color: #fff;
                                border-bottom-right-radius: 0.5rem;
                                left: 20px;
                                transform: translate(-30px, -2px);
                                width: 10px;
                            }
                            &:before{
                                bottom: -0.1rem;
                                content: "";
                                height: 1rem;
                                position: absolute;
                                border-bottom-right-radius: 0.8rem 0.7rem;
                                border-left: 1rem solid $middle_grey;
                                left: -0.35rem;
                                transform: translate(0, -0.1rem);
                            }
                        }
                        &.myMessage{
                            color:white;
                            background-color: $diagram_blue;
                            margin-left: auto;
                            &:after{
                                background-color: #fff;
                                border-bottom-left-radius: 0.5rem;
                                right: -40px;
                                transform: translate(-30px, -2px);
                                width: 10px;
                                bottom: -0.1rem;
                                content: "";
                                height: 1rem;
                                position: absolute;
                            }
                            &:before{
                                border-bottom-left-radius: 0.8rem 0.7rem;
                                border-right: 1rem solid $diagram_blue;
                                right: -0.35rem;
                                transform: translate(0, -0.1rem);
                                bottom: -0.1rem;
                                content: "";
                                height: 1rem;
                                position: absolute;
                            }
                        }
                    }
                    &.messageDeliveryError{
                        display: flex;
                        margin-bottom: 24px;
                        .messageChatError{
                            border:unset;
                            background-color: unset;
                            z-index: 1;
                            padding: 0;
                            margin-left: 15px;
                            width:20px;
                            display: flex;
                            align-items: flex-end;
                            //padding-bottom: 20px;
                            &:focus-visible{
                                border:unset;
                                outline:unset;
                            }
                        }
                        .messageStatus{
                            position: absolute;
                            right:0;
                            bottom:-24px
                        }
                    }
                    .messageErrorMenuContainer{
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        background-color: white;
                        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
                        border-radius: 4px; 
                        right:0;
                        bottom:-90px;
                        z-index: 4;
                        .messageErrorMenuItem{
                            display: flex;
                            padding: 13.5px 15px;
                            cursor: pointer;
                            font-size:12px;
                            font-weight: 400;
                            line-height: 20px;
                            
                            &.ordinaryItem{
                                color: $dark_grey
                            }
                            &.redItem{
                                color:$diagram_red;
                            }
                            img{
                                margin-right: 10px;
                            }
                        }
                    }
                    &.notMyMessageContainer{
                        .messageStatus{
                            justify-content: flex-start;
                        }
                    }
                }
                .messageStatus{
                    font-weight: 600;
                    font-size:11px;
                    line-height: 16px;
                    margin-top: 6px;
                    color:$chat_grey;
                    display: flex;
                    justify-content: flex-end;
                    span{
                        font-weight: 400;
                    }
                }
            }
            &::-webkit-scrollbar-thumb {
                height: 5px;
                width:5px;
                background: $light_blue;
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            &::-webkit-scrollbar {
                display: block;
                height: 5px;
                width:5px;
                margin: 5px 10px 5px 0px;
            }    
        }
        .messagesOverlay{
            width:100%;
            height: calc(100% + 50px);
            position: absolute;
            z-index: 2;
            left:0;
            top:0;
            margin-top: -50px;
            
        }
    }
}

