@import "../../../res/consts/colors.scss";

.dealDashboardMainTable{
    thead{
        border: unset;
        tr{
            border: unset;
            white-space: nowrap !important;
            th{
                border: unset;
            }
            &.firstHead{
                th{
                    font-size: 12px;
                    line-height: 16px;
                    color: $dark_grey;
                    font-weight: normal;
                    &.nameThreeColorsCol{
                        font-size: 10px;
                        line-height: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        &.urgency{
                            padding-left: 50px;
                        }
                        
                    }
                    span{
                        line-height: 20px;
                    }
                    &.mainTableFilter{
                        
                        .dropdownDropdownInput{
                            border: unset;
                        }
                        span{
                            font-size: 12px;
                            line-height: 20px;
                            color: $dark_grey;
                            font-weight: normal;
                            &:hover{
                                color:$dark_blue
                            }
                        }
                        img{
                            margin-left: 5px;
                            margin-bottom: 3px;
                        }
                    }
                }  
            }
            &.secondHead{
                background-color: $light_grey;
                border-radius:5px;
                th{
                    .scaleThreeColorsCol{
                        color:$dark_grey;
                        font-size: 10px;
                        font-weight: normal;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        height: 40px;
                        width:170px;
                        margin-left: 7px;
                        
                        div{
                            flex-grow: 1;
                        }
                    }              
                }
                .chosenItemTextDropdownInput{
                    font-size: 12px;
                    line-height: 20px;
                }
                .filterSecondHead{
                    width:230px
                }
            }
        }
    }
    tbody{
        border-top:unset !important;
        border-style:none !important;
        tr{
            cursor: pointer;
            td{
                padding-top: 20px !important;
                padding-bottom: 15px;
                .companyDetails{
                    color:$dark_grey;
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: normal;
                    margin-top: 5px;
                }
                &.companyCloseDate{
                    color: $dark_grey;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 16px;
                }
                .datathreeColors{
                    display: flex;
                    .levelDatathreeColors{
                        width:40px;
                        height:15px;
                        border-radius: 5px;
                        background-color: $light_grey;
                        margin-right: 15px;    
                    }
                    &.LowColor{
                        .low{
                            background-color: #FF0650;
                        }
                    }
                    &.MediumColor{
                        .mid{
                            background-color: #FFE071;
                        }
                    }
                    &.HighColor{
                        .high{
                            background-color: #32DDBF;
                        }
                    }   
                }
                .companyDetailsMessages{
                    position: absolute;
                    right:10px;
                    top:10px;
                }
            }
            
            &.activeOpportunity{
                border-radius: 10px !important;
                
                .companyDetails{
                    color:$light_blue;
                }
                .companyBlackText{
                    color:white;
                }
                .companyCloseDate{
                    color:$light_blue;
                }
                .datathreeColors{
                    display: flex;
                    .levelDatathreeColors{
                        background-color: $light_blue !important;
                    }
                    &.LowColor{
                        .low{
                            background-color: #FF0650 !important;
                        }
                    }
                    &.MediumColor{
                        .mid{
                            background-color: #FFE071 !important;
                        }
                    }
                    &.HighColor{
                        .high{
                            background-color: #32DDBF !important;
                        }
                    }   
                }
                td{
                    border: unset !important;
                    background-color: $diagram_blue;
                }
            }
            &.beforeActiveOpportunity{
                td{
                    border: unset !important;
                }
            }
        }

        td:first-child{
            border-radius: 5px 0 0 5px;
        }
        td:last-child{
            border-radius: 0 5px 5px 0;
        }

        .titleNoData{
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color:$dark_grey;
            border-bottom: 1px solid $light_grey;
            position: relative;
            height: 84px;
            div{
                padding: 20px 0;
                position: absolute;
            }
        }
    }
    tfoot{
        border-top:unset !important;
        td{
            padding-top: 20px;
            &.companyBlackText{
                padding-left: 0.5rem;
            }
        }
        
    }
    .companyBlackText{
        color: $black;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
    }
}

