.basicPageContainer{
    margin: 0 40px;

    &.container{
        padding: unset;
    }
    .basicPageContainerShadow{
        border: 1px solid #EAEEF1;
        box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
        border-radius: 5px;
    }

    @media(max-width:768px){
        border:unset;
        box-shadow: unset;
    }
}