@import "../../res/consts/colors.scss";

.goBackObjectiveDetailsPage{
    color: $dark_blue;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
    cursor: pointer;
    position: relative;
    z-index: 10;
}