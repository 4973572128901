.loaderContainer{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    z-index: 1000;
    left:0;
    .loaderContainerBackground{
        background-color: white;
        width: 100%;
        height: 100%;
        opacity: 0.5 !important;
    }
    img{
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        -webkit-animation: rotation 1s infinite linear;
        -moz-animation: rotation 1s infinite linear;
        -o-animation: rotation 1s infinite linear;
        animation: rotation 1s infinite linear;
    }
    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
        }
    }
    @-moz-keyframes rotation {
        from {
            -moz-transform: rotate(0deg);
        }
        to {
            -moz-transform: rotate(359deg);
        }
    }
    @-o-keyframes rotation {
        from {
            -o-transform: rotate(0deg);
        }
        to {
            -o-transform: rotate(359deg);
        }
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
}