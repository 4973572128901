.messagesPreviewOpenButton{
    background-color: unset;
    border:unset;
}
.messagesPreviewContainer{
    position: absolute;
    //max-width: 422px;
    background-color: white;
    //top:calc(100vh - 772px);
    //bottom: 0;
    box-shadow: -4px 0px 25px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 0 20px 20px 20px;
    z-index: 1000;
    width:382px;
    .messagesPreviewDrag{
        width:100%;
        text-align: center;
    }
    .messagesPreviewHeaderActions{
        width:100%;
        display: flex;
        div{
            flex-grow: 1;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: -38px;
            &.messagesPreviewOpenModal{
                z-index: 2;
                img{
                    width:20px
                }
                .messagesPreviewContainer{
                    
                }
            }
            &.messagesPreviewClose{
                z-index: 2;
                justify-content: flex-end;
                img{
                    width:20px
                }
            }
        }
    }
    .messagesModalHeader{
        font-size: 24px;
        
    }
    .messagesConteiner{
        border: unset
    }
    .messagesData{
        text-align: center;
    }
    .messagesPreviewOverlay{
        position: absolute;
        height: 30px;
        width: 100%;
        top: 0;
        left:0;
        .messagesPreviewOverlayCursor{
            margin:auto;
            height: 100%;
            width:30px;
            cursor:pointer;
        }
    }
}