//blue
$blue_gradient:linear-gradient(316.17deg, #2F9BFB -12.7%, #1562E5 55.13%, #0D52DE 98.99%);
$dark_blue:#0F56E0;
$light_blue:#6DB6FE;
$diagram_blue:#1E74EC;

//grey
$grey:#BABCC9;
$light_grey:#F6F8FC;
$dark_grey:#616161;
$middle_grey:#EAEEF1;
$chat_grey: #8E8E93;
$chat_grey_background: rgba(118, 118, 128, 0.12);

//green
$mint:#32DDBF;
$green:#75DA92;

$light_green:#E1FDF8;

//yellow
$light_yellow:#FBF3D7;
$yellow:#FFE071;

//red
$light_red:#FDC6D6;
$red: #FF0650;
$pink:#F7949E;
$light_pink:#F8E0E2;
$diagram_red:#E93355;

//black
$black:#1C1C1C;

//border
$border_white: #F6F8FC;