@import "../../res/consts/colors.scss";

form{
    &.textareaInputContainer{
        display: flex;
        padding: 15px;
        background: $light_grey;
        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-track { 
            border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
            background: $light_grey; 
            border-radius: 10px;
            height: 5px;
        }
        textarea{
            flex-grow: 1; 
            border:unset;
            background: unset;
            word-wrap: auto;
            word-break: normal;
            resize: none;
            display: block;
            margin-right: 5px;
            height: 35px;
            max-height: 76px;
            @media(min-width: 1600px ){
                max-height: 80px;
            }
           &:focus-visible{
               border:unset;
               outline:unset;
           }
           &::-webkit-scrollbar-thumb {
                width:5px;
                height: 5px;
                background-color: rgb(189,189,189);
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            &::-webkit-scrollbar {
                display: block;
                width:5px;
                height: 5px;
                background-color: $light_grey;
                margin: 5px 10px 5px 0px;
            }
        }
        button{
            padding: 0;
            border:unset;
            background-color: unset;
            margin-right: -5px;
        }
    }
}