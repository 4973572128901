@import "../../res/consts/colors.scss";

.containerObjectiveDiagrams{
    background: $diagram_blue;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    @media(max-width: 768px){
        flex-direction: column;
        align-items: center;
        .containerCardCarousel{
            margin-top: 20px;
        }
        .containerTotalDiagram{
            flex-direction: column-reverse;
            .rightTitleTotalDiagram{
                text-align: center;
                margin-left: unset;
                margin-bottom: 20px;
                span{
                    width:100%
                }
            }
        }
    }
}