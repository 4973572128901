@import "../../res/consts/colors.scss";
@import "../../res/consts/global_constants.scss";

header.mainHeader{
    background: $blue_gradient;
    
    .mainHeaderContainer{
        margin-left: 40px;
        margin-right: 60px;

        @media(max-width: 430px){
            margin-left: 20px;
            margin-right: 20px;
        }

        .contentContainerMainHeader{
            @media(max-width: 576px){
                flex-direction: column !important;
                width:100%;
                padding: 20px;
            }
            .initialsMainHeader{
                @media(max-width: 576px){
                    margin-top: 10px;
                }
            }
        }
        .align-items-center{
            position: relative;
        }
        .row{
            height: $header_height;
            @media(max-width: 576px){
                height: auto !important;
                .containerBreadcrumbsMainHeader{
                    margin-bottom: 0;
                }
            }
            @media(max-width: 811px){
                margin: unset;
                &.withBreadcrumbsMainHeader{
                    height: auto !important;
                    .containerBreadcrumbsMainHeader{
                        margin-left: auto;
                        @media(min-width: 577px){
                            margin-bottom: 24px;
                        }
                    }
                }
            }
            img{    
                @media(max-width: 576px){
                    margin: auto;
                }
                &.logo{
                    flex:unset;
                    width:unset;
                    cursor: pointer;
                }
            }
            .headerInitials{
                color:white;
                background-color: $dark_blue;
                text-align: center;
                width:32px;
                line-height: 32px;
                border-radius: 100%;
                font-weight: 700;
            }
            .headerUserName{
                white-space: nowrap;
                color:white;
                font-family: 'Open Sans', sans-serif !important;
            }
            .headerLogout{
                color:$light_blue;
                cursor: pointer;
            }
        } 
    }
}

.breadcrumb > .breadcrumb-item {
    @media(max-width: 430px){
        font-size: 19px;
    }
}