@import "../../res/consts/colors.scss";

.messagesModalContainer{
    .modal-header{
        .btn-close{
            background: url("../../res/img/closeMessagesIcon.svg");
            opacity: 1;
            width: 20px;
            height: 20px;
            padding: 0;
            background-size: cover;
        }
        &.resizeModalButton{
            flex-grow: 1;
            padding-left: 10px;
            .btn-close{
                background: url("../../res/img/resizeModalIcon.svg") !important;
                margin-left:unset;
                margin-right: auto;
            }
        }

    }
    .messagesConteinerTyping{
        //height: calc(100% - 156px) !important;
    }
    @media(min-width:576px){
        .modal-dialog{
            max-width: 700px;
        }
    }
}