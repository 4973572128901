@import "../../../res/consts/colors.scss";

.messagesConteiner{
    .messagesConteinerTyping{
        display: flex;
        width: calc(100% - 30px);
        height: 25px;
        position: absolute;
        background-color: white;
        margin-bottom: -10px;
        align-items: center;
        //bottom:92px;
        //margin-bottom: -6px;
        //height: calc(100% - 204px);
        span{
            color: $grey;
            font-size: 11px;
            line-height: 11px;
            margin-right: 5px;
        }
        #typingDotsWave{
            position:relative;
            text-align:center;
            margin-top: 0px;
            
            .dot{
                display:inline-block;
                width:2px;
                height:2px;
                border-radius:50%;
                margin-right:3px;
                background:$grey;
                animation: wave 1.3s linear infinite;

                &:nth-child(2) {
                    animation-delay: -1.1s;
                }

                &:nth-child(3) {
                    animation-delay: -0.9s;
                }
            }
        }
        @keyframes wave {
            0%, 60%, 100% {
                transform: initial;
            }
        
            30% {
                transform: translateY(-5px);
            }
        }
    }
}