@import "../../res/consts/colors.scss";

.containerObjectiveDetailsPage{
    @media(max-width: 765px){
        flex-direction: column;
        .questionsContainerObjectiveDetailsPage{
            width: 100%;
            padding: 12px !important;
            .questionsObjectiveDetailsPage{
                flex-direction: column;
                
                .col-5,.col-7{
                    width: 100% !important
                }
                .col-5{
                    margin-bottom: 24px;
                }
                
            }
        }
    }
    .diagramObjectiveDetailsPage{
        background: $diagram_blue;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(min-width:765px){
            min-height: calc(100vh - 148px);
        }
        @media(max-width:765px){
            width:100%;
            margin-bottom: 24px;
        }
        .arrowRightCardCarousel{
            position: absolute;
            top:calc(50% - 32px);
            right: 0;

            @media(max-width: 992px){
                right: 0 !important;
                top:calc(50% - 22px);
            }
        }

        .arrowLeftCardCarousel{
            position: absolute;
            top:calc(50% - 32px);
            left: 0;

            @media(max-width: 992px){
                left: 0 !important;
                top:calc(50% - 22px);
            }
        }
        .customStylesMessagesModal{
            //width:300px;
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
            @media (min-width: 1200px) and (max-width: 1399px) {
                .messagesPreviewContainer{
                    width: 255px !important;
                    padding: 0 10px 10px 10px;
                }
                .messagesModalHeader{
                    font-size: 18px;
                }
                .messagesPreviewHeaderActions{
                    div{
                        margin-bottom: -28px;
                        padding: 0 6px;
                    }
                }
            }
            .messagesPreviewContainer {
                width:301px;
                margin-bottom: -14px;
            }
            button{
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    color: white;
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 10px;
                }
            }
        }
    }
    
    .columnTopObjectiveDetailsPage{
        display: flex;
        align-items: center;
        .columnTitleObjectiveDetailsPage{
            color:black;
            font-size: 18px;
            font-weight: bold;
            margin-right: 20px;
        }
        .columnCountObjectiveDetailsPage{
            font-size: 14px;
            line-height: 14px;
            color: $dark_grey;
            display: flex;
            align-items: center;
            justify-content: center;
            width:24px;
            height: 24px;
            background-color: $light_grey;
        }
    }
    p{
        font-size: 14px;
        line-height: 19px;
        overflow-wrap: break-word;
        color:$black
    }
    hr{
        color:$middle_grey;
    }
    .answerContainerObjectiveDetailsPage{
        border-radius: 5px;
        &.defaultAnswerColor{
            background-color:$light_green;
        }
        &.highAnswerColor{
            background-color:$light_red;
        }
        &.lowAnswerColor{
            background-color:$light_pink;
        }
        .answerDetails{
            font-size: 10px;
            line-height: 14px;
            color:$dark_grey;
            div{
                text-transform: uppercase;
            }
        }
    }
    .messagesPreviewOpenModal{
        img{
            margin-left: -10px;
        }
    }
    .messagesPreviewClose{
        img{
            margin-right: -10px;
        }
    }
}